<template>
    <EntitySingle
      :config="config"
      :entity.sync="entity"
      :loaded.sync="entityLoaded"
      :extra-validation="extraValidation"
      :submit-callback="submitCallback"
      :disable-save="disableSave"
    >
      <b-card v-if="entityLoaded">
        <b-tabs pills>
          <b-tab active>
            <template #title>
              <feather-icon icon="UsersIcon" size="16" class="mr-0 mr-sm-50" />
              <span class="d-none d-sm-inline">{{ $t('Information') }}</span>
            </template>
            <EntityEditSection
              :module="config.module"
              :entity.sync="entity"
              :form-fields="formFields"
              allow-autofocus
            />                    
          </b-tab>
        </b-tabs>
      </b-card>
    </EntitySingle>
  </template>
  
  <script>
  import { BTab, BTabs, BCard, BFormCheckbox } from 'bootstrap-vue'
  import { translationUtils } from '@core/mixins/ui/translations'
  import { formUtils } from '@core/mixins/ui/forms'
  import {
    getUserRights,
    isAdmin,
    isSeller,
    isSupplier,
    canEditPrice,
    canPublishToShop,
    canUnPublishFromShop,
  } from '@/auth/utils'
  import EntityEditSection from '@/layouts/entity/EntityEditSection.vue'
  import EntitySingle from '@/layouts/entity/EntitySingle.vue'
  
  import storeModule from '../dealSubscriptionPaymentsStoreModule'
  
  export default {
    components: {
      EntityEditSection,
      EntitySingle,
      BTab,
      BTabs,
      BCard,
      BFormCheckbox,
    },
    mixins: [translationUtils, formUtils],
    data() {
      return {
        entity: {
          rights: [],
          userGroup: {},
          purchasedUser: {},
          company: {},
          active: true,
          status: '',
          // title: '',
          // subtitle: '',
          // transactionId: '',
          // enabled: false,
          // subscription: false,
          // monthlySubscription: '',
          // yearlySubscription: '',
          // maxProducts: 0,
          // description: '',

        },
        entityLoaded: false,
        rightsLoaded: false,
        grantAllRights: false,
        grantGroupRights: [],
        roleOptions: [],
        disableSave: true,
      }
    },
    computed: {
      config() {
        return {
          store: storeModule,
          module: 'subscriptions',
          endpoint: 'subscriptions',
          route: 'deal-subscription-payments',
          title: {
            single: this.$t('Deal Subscription Payment'),
            plural: this.$t('Deal Subscriptions Payment'),
          },
        }
      },
  
      formFields() {
        return [
        {
            id: 'title',
            name: 'userGroupTitle',
            parent: 'userGroup',
            object: true,
            type: 'text',
            label: this.$t('Subscription Name'),
            readonly: true,
          },
          {
            id: 'type',
            type: 'text',
            label: this.$t('Subscription Type'),
            readonly: true,
          },
          {
            id: 'name',
            parent: 'purchasedUser',
            type: 'text',
            label: this.$t('Subscriber Name'),
            rules: 'min:3',
            colSize: 4,
            readonly: true,
          },
          {
            id: 'title',
            name: 'companyTitle',
            parent: 'company',
            type: 'text',
            label: this.$t('Company Name'),
            rules: 'min:3',
            colSize: 4,
            readonly: true,
          },
          {
            id: 'paymentId',
            type: 'text',
            label: this.$t('Transaction ID'),
            rules: 'min:3',
            colSize: 4,
            readonly: true,
          },
          {
            id: 'amount',
            type: 'text',
            label: this.$t('Paid Amount'),
            rules: 'positive',
            readonly: true,
          },
          {
            id: 'startDate',
            type: 'datetime',
            label: this.$t('Subscription Start Date'),
            // maxDate: new Date(),
            readonly: true,
            disable: true,
          },
          {
            id: 'endDate',
            type: 'datetime',
            label: this.$t('Subscription End Date'),
            // maxDate: new Date(),
            readonly: true,

          },
        ]
      },
    },
    mounted() {

      this.$watch('entityLoaded', this.loadSave);
    },
    methods: {
      extraValidation() {
        return ''
      },
      submitCallback() {
        this.$router.push({ name: 'deal-subscription-payments-list' })
      },
      loadSave() {
        if(this.entity.status) {
          this.disableSave = false;
        } else {
          this.disableSave = true;

        } 
      },
    }
  }
  </script>
  